<template>
  <div class="popup_wrap" style="width:800px;">
    <button class="layer_close" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL010P070.001') }}</h1>
      <div class="content_box mt10 clearfix">
        <div class="col_4 float_left pr20">
          <h2 class="content_title">{{ $t('cp.CTRL010P070.002') }}</h2>
          <div id="realgrid" style="width: 100%; height: 600px" />
        </div>
        <div class="col_6 float_right">
          <h2 class="content_title">{{ $t('cp.CTRL010P070.003') }}</h2>
          <div id="realgrid2" style="width: 100%; height: 600px" />
        </div>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="select">{{ $t('cp.COMMON.002') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
let gridView = GridView
let provider = LocalDataProvider
let gridView2 = GridView
let provider2 = LocalDataProvider

const fields = [
  { fieldName: 'itemGrp', dataType: 'text' },
  { fieldName: 'itemGrpNm', dataType: 'text' }
]
const columns = [
  { name: 'itemGrpNm', fieldName: 'itemGrpNm', header: { text: app.$t('cp.CTRL010P070.004') }, editable: false, width: 100, styleName: 'text_left' }
]

const fields2 = [
  { fieldName: 'itemGrp', dataType: 'text' },
  { fieldName: 'itemGrpNm', dataType: 'text' },
  { fieldName: 'item', dataType: 'text' },
  { fieldName: 'itemNm', dataType: 'text' }
]
const columns2 = [
  { name: 'itemGrpNm', fieldName: 'itemGrpNm', header: { text: app.$t('cp.CTRL010P070.004') }, editable: false, width: 100, styleName: 'text_left' },
  { name: 'itemNm', fieldName: 'itemNm', header: { text: app.$t('cp.CTRL010P070.005') }, editable: false, width: 100, styleName: 'text_left' }
]

export default {
  name: 'CstSearchListPop',
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          position: '',
          itemGrp: [],
          item: []
        }
      }
    }
  },
  data () {
    return {
      itemGrp: [],
      item: [],
      chkHistory: []
    }
  },
  mounted () {
    const $vm = this
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    gridView.header.heights = [30]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'even'
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.setCheckBar({
      visible: true
    })
    gridView.footers.visible = false
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.displayOptions.selectionStyle = 'rows'

    provider2 = new LocalDataProvider(true)
    gridView2 = new GridView('realgrid2')
    gridView2.setDataSource(provider2)
    gridView2.header.heights = [30]
    gridView2.displayOptions.rowHeight = 34
    gridView2.displayOptions.fitStyle = 'even'
    gridView2.footers.visible = false
    gridView2.setStateBar({
      visible: false
    })
    gridView2.setCheckBar({
      visible: true
    })
    gridView2.footers.visible = false
    provider2.setFields(fields2)
    gridView2.setColumns(columns2)
    gridView2.displayOptions.selectionStyle = 'rows'
    gridView2.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    gridView.onItemAllChecked = (_grid, checked) => {
      if (checked) {
        const exceptitemGrp = [...new Set(provider2.getJsonRows().map(row => row.itemGrp))]
        const newitemGrp = gridView.getJsonRows().map(row => row.itemGrp).filter(itemGrp => !exceptitemGrp.includes(itemGrp))
        const lastItemGrpNm = gridView.getJsonRows()[0].itemGrpNm
        $vm.chkHistory.push(lastItemGrpNm)
        $vm.getCmdtItemList(newitemGrp.join(','))
      } else {
        $vm.chkHistory = []
        provider2.setRows([])
      }
    }

    gridView2.onItemAllChecked = (_grid, checked) => {
      if (checked) {
        const exceptItem = [...new Set(provider2.getJsonRows().map(row => row.item))]
        const newItem = gridView2.getJsonRows().map(row => row.item).filter(item => !exceptItem.includes(item))
        const lastItem = newItem.at(-1)
        const lastItemNm = $vm.itemData.filter(row => row.item === lastItem)[0].itemNm
        $vm.chkHistory.push(lastItemNm)
      } else {
        const lastItemGrp = gridView.getJsonRows().at(-1).itemGrpNm
        $vm.chkHistory = [lastItemGrp]
      }
    }

    gridView.onItemChecked = (_grid, itemIndex, checked) => {
      const itemGrp = provider.getValue(itemIndex, 'itemGrp')
      const itemGrpNm = provider.getValue(itemIndex, 'itemGrpNm')
      if (checked) {
        $vm.getCmdtItemList(itemGrp)
        $vm.chkHistory.push(itemGrpNm)
      } else {
        const delRowIdxList = provider2.getJsonRows().map((row, idx) => row.itemGrp === itemGrp ? idx : -1).filter(idx => idx !== -1).reverse()
        provider2.removeRows(delRowIdxList)

        if ($vm.chkHistory.at(-1) === itemGrpNm) {
          $vm.chkHistory.pop()
        }
      }
    }

    gridView2.onItemChecked = (_grid, itemIndex, checked) => {
      const itemNm = gridView2.getValue(itemIndex, 'itemNm')
      if (checked) {
        $vm.chkHistory.push(itemNm)
      } else if ($vm.chkHistory.at(-1) === itemNm) {
        $vm.chkHistory.pop()
      }
    }

    $vm.getCmdtGrpList()
    $vm.getCmdtItemList($vm.parentInfo.itemGrp.join(','))

    gridView.onDataLoadComplated = () => {
      gridView.setCheckBar({ syncHeadCheck: true })
      $vm.updateCheckState1()
    }

    gridView2.onDataLoadComplated = () => {
      gridView2.setCheckBar({ syncHeadCheck: true })
      $vm.updateCheckState2()
    }
  },
  methods: {
    getCmdtGrpList () {
      cpCommonInfo.cmdtGrpList().then(response => {
        const itemGrpData = response.data.map(row => ({
          itemGrp: row.cmdtGrpCd,
          itemGrpNm: row.cmdtGrpVal
        }))

        provider.setRows(itemGrpData)
      }).catch(err => {
        console.error(err)
      })
    },
    getCmdtItemList (itemGrp) {
      cpCommonInfo.cmdtItemList(itemGrp).then(response => {
        const itemData = response.data.map(row => ({
          itemGrp: row.cmdtGrpCd,
          itemGrpNm: row.cmdtGrpVal,
          item: row.cmdtCd,
          itemNm: row.cmdtVal
        }))

        provider2.fillJsonData(itemData, { fillMode: 'append', noStates: true })
      }).catch(err => {
        console.log(err)
      })
    },
    updateCheckState1 () {
      if (this.parentInfo.itemGrp.length !== 0) {
        const itemGrp = this.parentInfo.itemGrp
        const chkedRowIdxList = gridView.getJsonRows(0, -1).map((row, idx) => itemGrp.includes(row.itemGrp) ? idx : -1).filter(idx => idx !== -1)

        const sortTargetRows = []
        for (const idx of chkedRowIdxList) {
          sortTargetRows.push(provider.getRows(idx, idx)[0])
        }
        provider.removeRows(chkedRowIdxList)

        for (let i = sortTargetRows.length - 1; i > -1; i--) {
          const values = sortTargetRows[i]
          provider.insertRow(0, {
            itemGrp: values[0],
            itemGrpNm: values[1]
          })
        }

        for (let i = 0; i < sortTargetRows.length; i++) {
          gridView.checkItem(i, true, false, false)
        }
      }
    },
    updateCheckState2 () {
      if (this.parentInfo.item.length !== 0) {
        const item = this.parentInfo.item
        const chkedRowIdxList = gridView2.getJsonRows(0, -1).map((row, idx) => item.includes(row.item) ? idx : -1).filter(idx => idx !== -1)

        const sortTargetRows = []
        for (const idx of chkedRowIdxList) {
          sortTargetRows.push(provider2.getRows(idx, idx)[0])
        }
        provider2.removeRows(chkedRowIdxList)

        for (let i = sortTargetRows.length - 1; i > -1; i--) {
          const values = sortTargetRows[i]
          provider2.insertRow(0, {
            itemGrp: values[0],
            itemGrpNm: values[1],
            item: values[2],
            itemNm: values[3]
          })
        }
        gridView2.checkItems(Array(sortTargetRows.length).fill().map((_row, idx) => idx))
      }
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.005'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    layerClose: function () {
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    },
    select () {
      const itemGrp = []
      const item = []

      for (const chkedRowIdx of gridView.getCheckedRows()) {
        itemGrp.push(gridView.getValue(chkedRowIdx, 'itemGrp'))
      }
      for (const chkedRowIdx of gridView2.getCheckedRows()) {
        item.push(gridView2.getValue(chkedRowIdx, 'item'))
      }

      let lastItem = ''
      if (!['', undefined].includes(this.chkHistory.at(-1))) {
        lastItem = this.chkHistory.at(-1)
      } else if (itemGrp.length !== 0) {
        lastItem = gridView.getJsonRows().filter(row => row.itemGrp === itemGrp.at(-1))[0].itemGrpNm
      }

      this.$emit('close', { itemGrp: itemGrp, item: item, lastItem: lastItem })
      this.layerClose()
    }
  }
}
</script>
