var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "800px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("cp.CTRL010P070.001"))),
        ]),
        _c("div", { staticClass: "content_box mt10 clearfix" }, [
          _c("div", { staticClass: "col_4 float_left pr20" }, [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("cp.CTRL010P070.002"))),
            ]),
            _c("div", {
              staticStyle: { width: "100%", height: "600px" },
              attrs: { id: "realgrid" },
            }),
          ]),
          _c("div", { staticClass: "col_6 float_right" }, [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("cp.CTRL010P070.003"))),
            ]),
            _c("div", {
              staticStyle: { width: "100%", height: "600px" },
              attrs: { id: "realgrid2" },
            }),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.select.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cp.COMMON.002")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }